body {
    margin: 0;
    font-family: 'Arial';
    color: white;
    background-color:rgb(17, 24, 39);
}

.title, .title-first-letters {
    text-align: center;
    font-family: Consolas, Arial, monospace;
    color: teal;
    font-size: 60px;
    margin-bottom: 5px;
}

.title-first-letters {
    color: crimson;
    font-weight: bold;
}

.uni-logo {
    background-color: white;
    border-radius: 20px;
    height: 55px;
    margin-top: 10px;
    margin-right: 30px;
}

ul.header {
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-width: 400px;
}

li.header:nth-child(1) { grid-column-start: 2; }
li.header:nth-child(2) { margin-left: auto; }

.panel {
    margin: auto;
    background-color: rgb(10, 15, 25);
    border-radius: 25px;
    padding: 20px;
    font-size: 25px;
}

button, label.button {
    background-color: teal;
    color: white;
    font-size: 18.5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding: 5px 20px;
    border-radius: 15px;
    border-width: 0;
    align-items: center;
    margin-right: 8px;
}

button:hover, label.button:hover {
    background-color: #007070;
}
button:active, label.button:active {
    transform: scale(0.95);
}
.icon-button:hover {
    color: #007070;
}
.icon-button:active{
    transform: scale(0.9);
}


button.danger {
    background-color: crimson;
}
button.danger:hover {
    background-color: rgb(183, 16, 50);
}

button.right {
    float: right;
    margin-left: 8px;
}


input, select {
    background-color: #1e1e1e;
    color: white;
    font-size: 20px;
    font-family: Consolas, monospace;
    border-radius: 6px;
    border: 2px solid rgb(10, 15, 25);
}

input {
    text-align: right;
}

input.changed, select.changed {
    border: 2px solid teal;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background-color: #1e1e1e;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(71, 71, 71);
    border-radius: 6px;
}

.float-container {
    display: flex;
}

