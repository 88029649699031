button.up-button, button.down-button {
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 14px;
}

button.down-button {
    border-radius: 0px 0px 8px 8px;
    padding-top: 0px;
    border-top: 2px solid #005252;
    transform: translateY(-2px);
}

button.up-button {
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 2px;
    border-bottom: 2px solid #005252;
    transform: translateY(2px);

}

button.up-button:active {
    transform-origin: bottom;
    transform: scale(1) rotateX(-18deg) translateY(2px);

}
button.down-button:active {
    transform-origin: top;
    transform: scale(1) rotateX(18deg) translateY(-2px);
}