.console {
    width: 98.75%;
    margin-right: 100px;
    background-color: black;
    border-width: 0;
    color: white;
    font-size: 18px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    resize: none;
}