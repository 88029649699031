.simulation-page {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
}

.code-state-panel, .memory-panel {
    margin-top: 0px;
    margin-bottom: 0px;
}

.code-state-panel {
    width: 62%;
    min-width: 710px;
    max-width: 1450px;
    margin-right: 8px;
    overflow: hidden;
    align-self: center;
}

.memory-panel {
    width: 35%;
    min-width: 260px;
    max-width: 850px;
    color: white;
    font-size: 20px;
    font-family: Consolas, monospace;
    text-align: center;
    margin-left: 0px;
}

.state {
    text-align: center; 
    overflow-y: auto; 
    max-height: 550px;
    width: 35%;
}
