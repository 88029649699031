.monaco-editor-overlaymessage { 
    display: none !important; 
}

.current-instruction-background {
	background: rgba(71, 71, 71, 0.6);
}

.arrow, .breakpoint, .arrow-breakpoint, .breakpoint-preview, .arrow-breakpoint-preview {
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
}

.arrow {
    background-image: url(../../public/images/arrow.png);
}

.breakpoint {
    background-image: url(../../public/images/breakpoint.png);
}

.breakpoint-preview {
    background-image: url(../../public/images/breakpoint-preview.png);
}

.arrow-breakpoint {
    background-image: url(../../public/images/arrow-breakpoint.png);
}

.arrow-breakpoint-preview {
    background-image: url(../../public/images/arrow-breakpoint-preview.png);
}