.welcome-message {
    width: 60%;
    max-width: 1000px;
    min-width: 400px;
    font-weight: thin;
    text-align: center;
    font-size: 22px;
}

label.file-upload {
    display: inline-flex;
}
label.file-upload span {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-image: url(../../public/images/folder-upload-icon.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.dropdown {
    position: relative;
}
  
  .menu {
    z-index: 1;
    position: absolute;
    left: 10px;
    


    list-style-type: none;
    margin: 5px 0;
    padding: 0;
  
    width: 100px;
  }

  .menu > li:first-child {
    border-radius: 5px 5px 0px 0px;
  }

  .menu > li:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  
  .menu > li {

    
    background-color: teal;
  }
  
  .menu > li:hover {
    background-color: #007070;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }